import { useAuthParticipent } from "@/store/authParticipants";
import { useMiddleware } from "@/store/middleware";
import { checkSubdomainExist, isSpecialSubdomain } from "~/utils/helper";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuthParticipent();

  const middleware = useMiddleware();
  const host = window.location.host;
  const subdomain = host.split(".")[0];
  const config = useRuntimeConfig();

  const defaultRoute = `/sessions/${to.params.id}/${auth.model_type}/${to.params.user_id}`;

  if (!isSpecialSubdomain(auth.subdomain)) {
    try {
      //  Check if subdomain exists
      await checkSubdomainExist(middleware, subdomain);
    } catch (e) {
      await auth.logoutNoRoute();
      // Domain does not exist so redirect to primary login
      return navigateTo(`${config.public.primaryURL}/login`, {
        external: true,
      });
    }
  }

  //  Check if user is logged in if not refetch user cookie
  if (!auth.isSuccess) {
    try {
      await auth.refetch();
    } catch (e) {
      console.log(e);
    }
  }

  if (auth.isSuccess) {
    return navigateTo(`${defaultRoute}/switch`);
  }
});
